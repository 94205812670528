let ENGINE_API_KEY = "service:JoeDevGeeeee-2839:3vtgQng6FUHL6WIfT0szDQ";
let REACT_APP_GRAPHQL_ENDPOINT = "https://v4graphql.rumbleon.com/dev";
let REACT_APP_S3_LINK = "https://devsellimg.rumbleon.com";
let REACT_APP_SECURED_S3_LINK = "https://dev-doc-sf-ron-s3.s3.amazonaws.com";
let REACT_APP_SECURED_S3_KEY = "AKIAUZM55CJFVVPXAGHK"
let REACT_S3_ROTATE = "https://s3.amazonaws.com/bikeimages-ron-dev-s3";
let REACT_DEALER_S3_LINK = "https://devdlrimg.rumbleon.com";
let REACT_APP_CLS_API_ENDPOINT = "https://v4devclsapi.rumbleon.com";
let REACT_APP_ES_APP = "v4devlisting";
let REACT_DEALER_ES_APP = "v4devdealership";
let   REACT_APP_MAPS_CREDENTIALS = "AIzaSyAMbbBkddD11O3RX39-9mDXBywrNdzWYog";
let TCI_URL =
  "https://cp-demo.decisionlender.solutions/cp/#/rumb040/bike/newapp?";
let REACT_APP_GTM_ID = "GTM-NLTX2F8";
let REACT_APP_ES_CREDENTIALS = "dev-qa-ro:dX4FZJwcVF";
let REACT_APP_ES_LINK = "https://v4devesproxy.rumbleon.com";
let REACT_DEALER_ES_LINK =
  "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243";
let REACT_APP_RMBL_BASE = "https://v4devconsumerweb.rumbleon.com";
let INVENTORY_API_ENDPOINT = "https://v4devinventory.rumbleon.com";
let REACT_ES_BASIC_AUTH = "Basic ZGV2LXFhLXJvOmRYNEZaSndjVkY=";
let REACT_APP_CW_CASH_OFFER = "https://v4devcashoffer.rumbleon.com";
let REACT_APP_DEALER_DETAIL = "https://v4devorg.rumbleon.com"; //@todo: update
let REACT_APP_STRIPE_KEY = "pk_test_bZ8UlmeKkUzngIMF0WDLXx5L";
let REACT_APP_SEGMENT_KEY = "WxPVwF17NiKb5j9knGDWED6TnKpNGOSZ";
let SCHEMA_PATH = "https://v4graphql.rumbleon.com/dev";
let GOOGLE_RECAPTCHA_KEY = "6LfKAKYZAAAAAMROukZEPWDklu1bSjxVimITbVAP";
let ACCEPT_OFFER_SF_URL = "http://dev-rumbleon.cs41.force.com";
let DEALER_PORTAL_URL = "https://v4devdealer.rumbleon.com/";
let AUTH_API_ENDPOINT = "https://v4devaccounts.rumbleon.com";
let VINLESS_ES_URL =
  "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243";
let REACT_MARINEVINLESS_ES_APP = "v4pprodnadaymmmarine";
let REACT_MARINEVINLESS_ES_LINK =
  "https://b9bff4f64d8c49488791b9c344a8d05f.us-east-1.aws.found.io:9243";
let CAREERS_JOB = "https://v4pprodcareersapi.rumbleon.com/api/Careers/GetJobs";
let CAREERS_KEY = "Basic ZGV2LXFhLXJ3OkNrOXE5dTNKekc=";
if (window.location.hostname === "localhost") {
  // ENGINE_API_KEY = "service:JoeDevGeeeee-2839:3vtgQng6FUHL6WIfT0szDQ";
  // REACT_APP_GRAPHQL_ENDPOINT = "https://v4graphql.rumbleon.com/dev";
  // REACT_APP_S3_LINK = "https://devsellimg.rumbleon.com";
  // REACT_S3_ROTATE = "https://s3.amazonaws.com/bikeimages-ron-dev-s3";
  // REACT_DEALER_S3_LINK = "https://devdlrimg.rumbleon.com";
  // REACT_APP_CLS_API_ENDPOINT = "https://v4devclsapi.rumbleon.com";
  // REACT_APP_ES_APP = "v4devlisting";
  // REACT_DEALER_ES_APP = "v4devdealership";
  // TCI_URL =
  //   "https://cp-demo.decisionlender.solutions/cp/#/rumb040/bike/newapp?";
  // REACT_APP_GTM_ID = "GTM-NLTX2F8";
  // REACT_APP_ES_CREDENTIALS = "dev-qa-ro:dX4FZJwcVF";
  // REACT_ES_BASIC_AUTH = "Basic ZGV2LXFhLXJvOmRYNEZaSndjVkY=";
  // REACT_APP_ES_LINK = "https://v4devesproxy.rumbleon.com";
  // REACT_DEALER_ES_LINK =
  //   "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243";
  // REACT_APP_RMBL_BASE = "https://v4devconsumerweb.rumbleon.com";
  // INVENTORY_API_ENDPOINT = "https://v4devinventory.rumbleon.com";
  // REACT_APP_CW_CASH_OFFER = "https://v4devcashoffer.rumbleon.com";
  // REACT_APP_DEALER_DETAIL = "https://v4devorg.rumbleon.com"; //@todo: update
  // REACT_APP_STRIPE_KEY = "pk_test_bZ8UlmeKkUzngIMF0WDLXx5L";
  // REACT_APP_SEGMENT_KEY = "WxPVwF17NiKb5j9knGDWED6TnKpNGOSZ";
  // SCHEMA_PATH = "https://v4graphql.rumbleon.com/dev";
  // GOOGLE_RECAPTCHA_KEY = "6LfKAKYZAAAAAMROukZEPWDklu1bSjxVimITbVAP";
  // ACCEPT_OFFER_SF_URL = "http://dev-rumbleon.cs41.force.com";
  // DEALER_PORTAL_URL = "https://v4devdealer.rumbleon.com/";
  // AUTH_API_ENDPOINT = "https://v4devaccounts.rumbleon.com";

  // ENGINE_API_KEY = "service:CLS-QA:K_l1Qdtm9K5OiZzOQocydA";
  // REACT_APP_GRAPHQL_ENDPOINT =
  //   "https://afead39mof.execute-api.us-east-1.amazonaws.com/qa";
  // REACT_APP_S3_LINK = "https://qasellimg.rumbleon.com";
  // REACT_DEALER_S3_LINK = "https://qadlrimg.rumbleon.com";
  // REACT_APP_CLS_API_ENDPOINT = "https://v4qaclsapi.rumbleon.com";
  // REACT_APP_ES_APP = "v4qalisting";
  // REACT_DEALER_ES_APP = "v4qadealership";
  // REACT_APP_GTM_ID = "GTM-NLTX2F8";
  // REACT_APP_ES_CREDENTIALS = "dev-qa-ro:dX4FZJwcVF";
  // REACT_APP_ES_LINK = "https://v4qaesproxy.rumbleon.com";
  // REACT_APP_RMBL_BASE = "https://v4qaconsumerweb.rumbleon.com";
  // REACT_APP_CW_CASH_OFFER = "https://v4qacashoffer.rumbleon.com";
  // //REACT_APP_DEALER_DETAIL //@todo: update
  // REACT_APP_STRIPE_KEY = "pk_test_bZ8UlmeKkUzngIMF0WDLXx5L";
  // REACT_APP_SEGMENT_KEY = "WxPVwF17NiKb5j9knGDWED6TnKpNGOSZ";
  // SCHEMA_PATH = "https://afead39mof.execute-api.us-east-1.amazonaws.com/qa";
  // GOOGLE_RECAPTCHA_KEY = "6LfKAKYZAAAAAMROukZEPWDklu1bSjxVimITbVAP";
  // ACCEPT_OFFER_SF_URL = "https://rithomdev-rumbleon.cs25.force.com";

  // ENGINE_API_KEY = "service:CLS-PPROD:zcQOVja2uoZxs4Jd3ZmjHQ";
  // REACT_APP_GRAPHQL_ENDPOINT =
  //   "https://38xu3d4ix6.execute-api.us-east-1.amazonaws.com/pprod";
  // REACT_APP_S3_LINK = "https://sellimg.rumbleon.com";
  // REACT_S3_ROTATE = "https://s3.amazonaws.com/rumbleon-sell-images";
  // REACT_DEALER_S3_LINK = "https://dlrimg.rumbleon.com";
  // REACT_APP_CLS_API_ENDPOINT = "https://v4pprodclsapi.rumbleon.com";
  // REACT_APP_ES_APP = "v4pplisting";
  // REACT_DEALER_ES_APP = "v4ppdealership";
  // REACT_APP_GTM_ID = "GTM-NLTX2F8";
  // REACT_APP_ES_CREDENTIALS = "pp-p-ro:sa2SMv7q53";
  // REACT_APP_ES_LINK =
  //   "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243";
  // REACT_DEALER_ES_LINK =
  //   "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243";
  // REACT_APP_RMBL_BASE = "https://v4pprodconsumerweb.rumbleon.com";
  // REACT_APP_CW_CASH_OFFER = "https://v4pprodcashoffer.rumbleon.com";
  // REACT_APP_DEALER_DETAIL = "https://v4pprodorg.rumbleon.com"; //@todo: update
  // REACT_APP_STRIPE_KEY = "pk_test_bZ8UlmeKkUzngIMF0WDLXx5L";
  // REACT_APP_SEGMENT_KEY = "WxPVwF17NiKb5j9knGDWED6TnKpNGOSZ";
  // SCHEMA_PATH = "https://38xu3d4ix6.execute-api.us-east-1.amazonaws.com/pprod";
  // GOOGLE_RECAPTCHA_KEY = "6LfKAKYZAAAAAMROukZEPWDklu1bSjxVimITbVAP";
  // ACCEPT_OFFER_SF_URL = "https://pprodron-rumbleon.cs40.force.com";
  // DEALER_PORTAL_URL = "https://v4pproddealer.rumbleon.com";

  // ENGINE_API_KEY = "service:CLS-PROD:wz0Hjcagrfb_aEQRm6bqPQ";
  // REACT_APP_GRAPHQL_ENDPOINT =
  //   "https://lr4xueuy09.execute-api.us-east-1.amazonaws.com/production/";
  // REACT_APP_S3_LINK = "https://sellimg.rumbleon.com";
  // REACT_S3_ROTATE = "https://s3.amazonaws.com/rumbleon-sell-images";
  // REACT_DEALER_S3_LINK = "https://dlrimg.rumbleon.com";
  // REACT_APP_CLS_API_ENDPOINT = "https://clsapi.rumbleon.com";
  // REACT_APP_ES_APP = "v4prodlisting";
  // REACT_DEALER_ES_APP = "v4proddealership";
  // REACT_APP_GTM_ID = "GTM-NLTX2F8";
  // REACT_APP_ES_CREDENTIALS = "pp-p-ro:sa2SMv7q53";
  // REACT_APP_ES_LINK = "https://prodesproxy.rumbleon.com";
  // REACT_DEALER_ES_LINK =
  //   "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243";
  // REACT_APP_RMBL_BASE = "https://www.rumbleon.com";
  // INVENTORY_API_ENDPOINT = "https://inventory.rumbleon.com";
  // REACT_APP_CW_CASH_OFFER = "https://cashoffer.rumbleon.com";
  // REACT_APP_DEALER_DETAIL = "https://org.rumbleon.com";
  // REACT_APP_STRIPE_KEY = "sk_live_pIYw9bnADfs141pksMRpT2Ez";
  // REACT_APP_SEGMENT_KEY = "KbQHjkSQfITWUPPH853oOUAIw6xXpu9W";
  // SCHEMA_PATH =
  //   "https://lr4xueuy09.execute-api.us-east-1.amazonaws.com/production/";
  // GOOGLE_RECAPTCHA_KEY = "6LfKAKYZAAAAAMROukZEPWDklu1bSjxVimITbVAP";
  // ACCEPT_OFFER_SF_URL = "https://rumbleon.secure.force.com/";
  // DEALER_PORTAL_URL = "https://dealer.rumbleon.com";

  // ENGINE_API_KEY = "service:CLS-PROD:wz0Hjcagrfb_aEQRm6bqPQ";
  // REACT_APP_GRAPHQL_ENDPOINT =
  //   "https://lr4xueuy09.execute-api.us-east-1.amazonaws.com/production/";
  // REACT_APP_S3_LINK = "https://sellimg.rumbleon.com";
  // REACT_S3_ROTATE = "https://s3.amazonaws.com/rumbleon-sell-images";
  // REACT_DEALER_S3_LINK = "https://dlrimg.rumbleon.com";
  // REACT_APP_CLS_API_ENDPOINT = "https://clsapi.rumbleon.com";
  // REACT_APP_ES_APP = "v4prodlisting";
  // REACT_DEALER_ES_APP = "v4proddealership";
  // REACT_APP_GTM_ID = "GTM-NLTX2F8";
  // REACT_APP_ES_CREDENTIALS = "pp-p-ro:sa2SMv7q53";
  // REACT_APP_ES_LINK = "https://prodesproxy.rumbleon.com";
  // REACT_DEALER_ES_LINK =
  //   "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243";
  // REACT_APP_RMBL_BASE = "https://www.rumbleon.com";
  // INVENTORY_API_ENDPOINT = "https://inventory.rumbleon.com";
  // REACT_APP_CW_CASH_OFFER = "https://cashoffer.rumbleon.com";
  // REACT_APP_DEALER_DETAIL = "https://org.rumbleon.com";
  // REACT_APP_STRIPE_KEY = "sk_live_pIYw9bnADfs141pksMRpT2Ez";
  // REACT_APP_SEGMENT_KEY = "KbQHjkSQfITWUPPH853oOUAIw6xXpu9W";
  // SCHEMA_PATH =
  //   "https://lr4xueuy09.execute-api.us-east-1.amazonaws.com/production/";
  // GOOGLE_RECAPTCHA_KEY = "6LfKAKYZAAAAAMROukZEPWDklu1bSjxVimITbVAP";
  // ACCEPT_OFFER_SF_URL = "https://rumbleon.secure.force.com/";
  // DEALER_PORTAL_URL = "https://dealer.rumbleon.com";

  // ENGINE_API_KEY = "service:CLS-PPROD:zcQOVja2uoZxs4Jd3ZmjHQ";
  // REACT_APP_GRAPHQL_ENDPOINT = "https://v4graphql.rumbleon.com/pprod";
  // REACT_APP_S3_LINK = "https://sellimg.rumbleon.com";
  // REACT_S3_ROTATE = "https://s3.amazonaws.com/rumbleon-sell-images";
  // REACT_DEALER_S3_LINK = "https://dlrimg.rumbleon.com";
  // REACT_APP_CLS_API_ENDPOINT = "https://v4pprodclsapi.rumbleon.com";
  // REACT_APP_ES_APP = "v4pplisting";
  // REACT_DEALER_ES_APP = "v4ppdealership";
  // REACT_APP_GTM_ID = "GTM-NLTX2F8";
  // REACT_APP_ES_CREDENTIALS = "pp-p-ro:sa2SMv7q53";
  // REACT_ES_BASIC_AUTH = "Basic cHAtcC1ybzpzYTJTTXY3cTUz";
  // REACT_APP_ES_LINK = "https://v4pprodesproxy.rumbleon.com";
  // REACT_DEALER_ES_LINK =
  //   "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243";
  // REACT_APP_RMBL_BASE = "https://v4pprodconsumerweb.rumbleon.com";
  // INVENTORY_API_ENDPOINT = "https://v4pprodinventory.rumbleon.com";
  // REACT_APP_CW_CASH_OFFER = "https://v4pprodcashoffer.rumbleon.com";
  // REACT_APP_DEALER_DETAIL = "https://v4pprodorg.rumbleon.com"; //@todo: update
  // REACT_APP_STRIPE_KEY = "pk_test_bZ8UlmeKkUzngIMF0WDLXx5L";
  // REACT_APP_SEGMENT_KEY = "WxPVwF17NiKb5j9knGDWED6TnKpNGOSZ";
  // SCHEMA_PATH = "https://v4graphql.rumbleon.com/pprod";
  // GOOGLE_RECAPTCHA_KEY = "6LfKAKYZAAAAAMROukZEPWDklu1bSjxVimITbVAP";
  // ACCEPT_OFFER_SF_URL = "http://dlr-rumbleon.cs50.force.com";
  // DEALER_PORTAL_URL = "https://v4pproddealer.rumbleon.com";
  // AUTH_API_ENDPOINT = "https://v4pprodaccounts.rumbleon.com";

  // ENGINE_API_KEY = "service:CLS-PPROD:zcQOVja2uoZxs4Jd3ZmjHQ";
  // REACT_APP_GRAPHQL_ENDPOINT = "https://v4graphql.rumbleon.com/pprod";
  // REACT_APP_S3_LINK = "https://sellimg.rumbleon.com";
  // REACT_S3_ROTATE = "https://s3.amazonaws.com/rumbleon-sell-images";
  // REACT_DEALER_S3_LINK = "https://dlrimg.rumbleon.com";
  // REACT_APP_CLS_API_ENDPOINT = "https://v4pprodclsapi.rumbleon.com";
  // REACT_APP_ES_APP = "v4pplisting";
  // REACT_DEALER_ES_APP = "v4ppdealership";
  // TCI_URL =
  //   "https://cp-demo.decisionlender.solutions/cp/#/rumb040/bike/newapp?";
  // REACT_APP_GTM_ID = "GTM-NLTX2F8";
  // REACT_APP_ES_CREDENTIALS = "pp-p-ro:sa2SMv7q53";
  // REACT_ES_BASIC_AUTH = "Basic cHAtcC1ybzpzYTJTTXY3cTUz";
  // REACT_APP_ES_LINK = "https://v4pprodesproxy.rumbleon.com";
  // REACT_DEALER_ES_LINK =
  //   "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243";
  // REACT_APP_RMBL_BASE = "https://v4pprodconsumerweb.rumbleon.com";
  // INVENTORY_API_ENDPOINT = "https://v4pprodinventory.rumbleon.com";
  // REACT_APP_CW_CASH_OFFER = "https://v4pprodcashoffer.rumbleon.com";
  // REACT_APP_DEALER_DETAIL = "https://v4pprodorg.rumbleon.com"; //@todo: update
  // REACT_APP_STRIPE_KEY = "pk_test_bZ8UlmeKkUzngIMF0WDLXx5L";
  // REACT_APP_SEGMENT_KEY = "WxPVwF17NiKb5j9knGDWED6TnKpNGOSZ";
  // SCHEMA_PATH = "https://v4graphql.rumbleon.com/pprod";
  // GOOGLE_RECAPTCHA_KEY = "6LfKAKYZAAAAAMROukZEPWDklu1bSjxVimITbVAP";
  // ACCEPT_OFFER_SF_URL = "http://dlr-rumbleon.cs50.force.com";
  // DEALER_PORTAL_URL = "https://v4pproddealer.rumbleon.com";
  // AUTH_API_ENDPOINT = "https://v4pprodaccounts.rumbleon.com";

  // ENGINE_API_KEY = "service:CLS-PPROD:zcQOVja2uoZxs4Jd3ZmjHQ";
  // REACT_APP_GRAPHQL_ENDPOINT =
  //   "https://v4graphql.rumbleon.com/demo-enviornment";
  // REACT_APP_S3_LINK = "https://sellimg.rumbleon.com";
  // REACT_S3_ROTATE = "https://s3.amazonaws.com/rumbleon-sell-images";
  // REACT_DEALER_S3_LINK = "https://dlrimg.rumbleon.com";
  // REACT_APP_CLS_API_ENDPOINT = "https://democlsapi.rumbleon.com";
  // REACT_APP_ES_APP = "demolisting";
  // REACT_DEALER_ES_APP = "demodealership";
  // TCI_URL =
  //   "https://cp-demo.decisionlender.solutions/cp/#/rumb040/bike/newapp?";
  // REACT_APP_GTM_ID = "GTM-NLTX2F8";
  // REACT_APP_ES_CREDENTIALS = "pp-p-ro:sa2SMv7q53";
  // REACT_ES_BASIC_AUTH = "Basic cHAtcC1ybzpzYTJTTXY3cTUz";
  // REACT_APP_ES_LINK = "https://demoesproxy.rumbleon.com";
  // REACT_DEALER_ES_LINK =
  //   "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243";
  // REACT_APP_RMBL_BASE = "https://democonsumerweb.rumbleon.com";
  // INVENTORY_API_ENDPOINT = "https://demoinventory.rumbleon.com";
  // REACT_APP_CW_CASH_OFFER = "https://democashoffer.rumbleon.com";
  // REACT_APP_DEALER_DETAIL = "https://demoorg.rumbleon.com";
  // REACT_APP_STRIPE_KEY = "pk_test_bZ8UlmeKkUzngIMF0WDLXx5L";
  // REACT_APP_SEGMENT_KEY = "WxPVwF17NiKb5j9knGDWED6TnKpNGOSZ";
  // SCHEMA_PATH = "https://v4graphql.rumbleon.com/production";
  // GOOGLE_RECAPTCHA_KEY = "6LfKAKYZAAAAAMROukZEPWDklu1bSjxVimITbVAP";
  // ACCEPT_OFFER_SF_URL = "http://demo-rumbleon.cs22.force.com";
  // DEALER_PORTAL_URL = "https://demodealer.rumbleon.com";
  // AUTH_API_ENDPOINT = "https://demoaccounts.rumbleon.com";

  ENGINE_API_KEY = "service:CLS-PPROD:zcQOVja2uoZxs4Jd3ZmjHQ";
  REACT_APP_GRAPHQL_ENDPOINT = "https://v4graphql.rumbleon.com/pprod";
  REACT_APP_S3_LINK = "https://sellimg.rumbleon.com";
  REACT_APP_SECURED_S3_LINK = "https://prod-doc-sf-ron-s3.s3.amazonaws.com";
  REACT_APP_SECURED_S3_KEY = "AKIAUZM55CJFVVPXAGHK"
  REACT_S3_ROTATE = "https://s3.amazonaws.com/rumbleon-sell-images";
  REACT_DEALER_S3_LINK = "https://dlrimg.rumbleon.com";
  REACT_APP_CLS_API_ENDPOINT = "https://v4pprodclsapi.rumbleon.com";
  REACT_APP_ES_APP = "v4pplisting";
  REACT_DEALER_ES_APP = "v4ppdealership";
  REACT_APP_MAPS_CREDENTIALS = "AIzaSyAMbbBkddD11O3RX39-9mDXBywrNdzWYog";
  TCI_URL =
    "https://cp-demo.decisionlender.solutions/cp/#/rumb040/bike/newapp?";
  REACT_APP_GTM_ID = "GTM-NLTX2F8";
  REACT_APP_ES_CREDENTIALS = "cweb-pprod-ro:nw$bR!2cs879mo";
  REACT_ES_BASIC_AUTH = "Basic Y3dlYi1wcHJvZC1ybzpudyRiUiEyY3M4Nzltbw==";
  REACT_APP_ES_LINK = "https://v4pprodesproxy.rumbleon.com";
  REACT_DEALER_ES_LINK =
    "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243";
  REACT_APP_RMBL_BASE = "https://v4pprodconsumerweb.rumbleon.com";
  INVENTORY_API_ENDPOINT = "https://v4pprodinventory.rumbleon.com";
  REACT_APP_CW_CASH_OFFER = "https://v4pprodcashoffer.rumbleon.com";
  REACT_APP_DEALER_DETAIL = "https://v4pprodorg.rumbleon.com"; //@todo: update
  REACT_APP_STRIPE_KEY = "pk_test_bZ8UlmeKkUzngIMF0WDLXx5L";
  REACT_APP_SEGMENT_KEY = "WxPVwF17NiKb5j9knGDWED6TnKpNGOSZ";
  SCHEMA_PATH = "https://v4graphql.rumbleon.com/pprod";
  GOOGLE_RECAPTCHA_KEY = "6LfKAKYZAAAAAMROukZEPWDklu1bSjxVimITbVAP";
  ACCEPT_OFFER_SF_URL =
    "https://rumbleon--preprod.sandbox.my.salesforce-sites.com";
  DEALER_PORTAL_URL = "https://v4pproddealer.rumbleon.com";
  AUTH_API_ENDPOINT = "https://v4pprodaccounts.rumbleon.com";
  VINLESS_ES_URL =
    "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243";
  REACT_MARINEVINLESS_ES_APP = "v4pprodnadaymmmarine";
  REACT_MARINEVINLESS_ES_LINK =
    "https://b9bff4f64d8c49488791b9c344a8d05f.us-east-1.aws.found.io:9243";
  CAREERS_JOB = "https://v4pprodcareersapi.rumbleon.com/api/Careers/GetJobs";
  CAREERS_KEY = "Basic ZGV2LXFhLXJ3OkNrOXE5dTNKekc=";
}
// Demo
if (window.location.hostname === "democonsumerweb.rumbleon.com") {
  ENGINE_API_KEY = "service:CLS-PPROD:zcQOVja2uoZxs4Jd3ZmjHQ";
  REACT_APP_GRAPHQL_ENDPOINT =
    "https://v4graphql.rumbleon.com/demo-enviornment";
  REACT_APP_S3_LINK = "https://sellimg.rumbleon.com";
  REACT_S3_ROTATE = "https://s3.amazonaws.com/rumbleon-sell-images";
  REACT_DEALER_S3_LINK = "https://dlrimg.rumbleon.com";
  REACT_APP_CLS_API_ENDPOINT = "https://democlsapi.rumbleon.com";
  REACT_APP_ES_APP = "demolisting";
  REACT_DEALER_ES_APP = "demodealership";
  TCI_URL =
    "https://cp-demo.decisionlender.solutions/cp/#/rumb040/bike/newapp?";
  REACT_APP_GTM_ID = "GTM-NLTX2F8";
  REACT_APP_ES_CREDENTIALS = "demo-is-ro:Ck9q9u3JzG";
  REACT_APP_MAPS_CREDENTIALS = "AIzaSyAMbbBkddD11O3RX39-9mDXBywrNdzWYog";
  REACT_ES_BASIC_AUTH = "Basic cHAtcC1ybzpzYTJTTXY3cTUz";
  REACT_APP_ES_LINK = "https://demoesproxy.rumbleon.com";
  REACT_DEALER_ES_LINK =
    "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243";
  REACT_APP_RMBL_BASE = "https://democonsumerweb.rumbleon.com";
  INVENTORY_API_ENDPOINT = "https://demoinventory.rumbleon.com";
  REACT_APP_CW_CASH_OFFER = "https://democashoffer.rumbleon.com";
  REACT_APP_DEALER_DETAIL = "https://demoorg.rumbleon.com";
  REACT_APP_STRIPE_KEY = "pk_test_bZ8UlmeKkUzngIMF0WDLXx5L";
  REACT_APP_SEGMENT_KEY = "WxPVwF17NiKb5j9knGDWED6TnKpNGOSZ";
  SCHEMA_PATH = "https://v4graphql.rumbleon.com/production";
  GOOGLE_RECAPTCHA_KEY = "6LfKAKYZAAAAAMROukZEPWDklu1bSjxVimITbVAP";
  ACCEPT_OFFER_SF_URL =
    "https://rumbleon--demo.sandbox.my.salesforce-sites.com/";
  DEALER_PORTAL_URL = "https://demodealer.rumbleon.com";
  AUTH_API_ENDPOINT = "https://demoaccounts.rumbleon.com";
  VINLESS_ES_URL =
    "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243";
  // REACT_MARINEVINLESS_ES_APP = "devnadaymmmarine";
  // REACT_MARINEVINLESS_ES_LINK =
  //   "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243";
}
// QA
if (window.location.hostname === "qa-v5consumerweb.rumbleon.com") {
  ENGINE_API_KEY = "service:CLS-QA:K_l1Qdtm9K5OiZzOQocydA";
  REACT_APP_GRAPHQL_ENDPOINT = "https://v4graphql.rumbleon.com/qa";
  REACT_APP_S3_LINK = "https://qasellimg.rumbleon.com";
  REACT_APP_SECURED_S3_LINK = "https://prod-doc-sf-ron-s3.s3.amazonaws.com";
  REACT_APP_SECURED_S3_KEY = "AKIAUZM55CJFVVPXAGHK";
  REACT_S3_ROTATE = "https://s3.amazonaws.com/bikeimages-ron-dev-s3";
  REACT_APP_MAPS_CREDENTIALS = "AIzaSyAMbbBkddD11O3RX39-9mDXBywrNdzWYog";
  REACT_DEALER_S3_LINK = "https://qadlrimg.rumbleon.com";
  REACT_APP_CLS_API_ENDPOINT = "https://v4qaclsapi.rumbleon.com";
  REACT_APP_ES_APP = "v4qalisting";
  REACT_DEALER_ES_APP = "v4qadealership";
  TCI_URL =
    "https://cp-demo.decisionlender.solutions/cp/#/rumb040/bike/newapp?";
  REACT_APP_GTM_ID = "GTM-NLTX2F8";
  REACT_APP_ES_CREDENTIALS = "dev-qa-ro:dX4FZJwcVF";
  REACT_APP_ES_LINK = "https://v4qaesproxy.rumbleon.com";
  REACT_ES_BASIC_AUTH = "Basic ZGV2LXFhLXJvOmRYNEZaSndjVkY=";
  REACT_DEALER_ES_LINK =
    "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243";
  REACT_APP_RMBL_BASE = "https://v4qaconsumerweb.rumbleon.com";
  REACT_APP_CW_CASH_OFFER = "https://v4qacashoffer.rumbleon.com";
  INVENTORY_API_ENDPOINT = "https://v4qainventory.rumbleon.com";
  REACT_APP_DEALER_DETAIL = "https://v4qaorg.rumbleon.com"; //@todo: update
  REACT_APP_STRIPE_KEY = "pk_test_bZ8UlmeKkUzngIMF0WDLXx5L";
  REACT_APP_SEGMENT_KEY = "WxPVwF17NiKb5j9knGDWED6TnKpNGOSZ";
  SCHEMA_PATH = "https://v4graphql.rumbleon.com/qa";
  GOOGLE_RECAPTCHA_KEY = "6LfKAKYZAAAAAMROukZEPWDklu1bSjxVimITbVAP";
  ACCEPT_OFFER_SF_URL = "https://rithomdev-rumbleon.cs25.force.com";
  DEALER_PORTAL_URL = "https://v4qadealer.rumbleon.com/";
  AUTH_API_ENDPOINT = "https://v4qaaccounts.rumbleon.com";
  VINLESS_ES_URL =
    "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243";
  CAREERS_JOB = "https://v4qacareersapi.rumbleon.com/api/Careers/GetJobs";
  CAREERS_KEY = "Basic ZGV2LXFhLXJ3OkNrOXE5dTNKekc=";
  // REACT_MARINEVINLESS_ES_APP = "v4pprodnadaymmmarine";
  // REACT_MARINEVINLESS_ES_LINK =
  //   "https://b9bff4f64d8c49488791b9c344a8d05f.us-east-1.aws.found.io:9243";
}
// PPROD
if (
  // window.location.hostname === "pprod-v5consumerweb.rumbleon.com" ||
  window.location.hostname === "pprod-v5consumerweb.rumbleon.com" ||
  window.location.hostname === "http://ppvirginiaconsumerweb.rumbleon.com" ||
  window.location.hostname === "http://ppohioconsumerweb.rumbleon.com"
) {
  ENGINE_API_KEY = "service:CLS-PPROD:zcQOVja2uoZxs4Jd3ZmjHQ";
  REACT_APP_GRAPHQL_ENDPOINT = "https://v4graphql.rumbleon.com/pprod";
  REACT_APP_S3_LINK = "https://sellimg.rumbleon.com";
  REACT_APP_SECURED_S3_LINK = "https://prod-doc-sf-ron-s3.s3.amazonaws.com";
  REACT_APP_SECURED_S3_KEY = "AKIAUZM55CJFVVPXAGHK";
  REACT_S3_ROTATE = "https://s3.amazonaws.com/rumbleon-sell-images";
  REACT_APP_MAPS_CREDENTIALS = "AIzaSyAMbbBkddD11O3RX39-9mDXBywrNdzWYog";
  REACT_DEALER_S3_LINK = "https://dlrimg.rumbleon.com";
  REACT_APP_CLS_API_ENDPOINT = "https://v4pprodclsapi.rumbleon.com";
  REACT_APP_ES_APP = "v4pplisting";
  REACT_DEALER_ES_APP = "v4ppdealership";
  TCI_URL =
    "https://cp-demo.decisionlender.solutions/cp/#/rumb040/bike/newapp?";
  REACT_APP_GTM_ID = "GTM-NLTX2F8";
  REACT_APP_ES_CREDENTIALS = "cweb-pprod-ro:nw$bR!2cs879mo";
  REACT_ES_BASIC_AUTH = "Basic Y3dlYi1wcHJvZC1ybzpudyRiUiEyY3M4Nzltbw==";
  REACT_APP_ES_LINK = "https://v4pprodesproxy.rumbleon.com";
  REACT_DEALER_ES_LINK =
    "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243";
  REACT_APP_RMBL_BASE = "https://v4pprodconsumerweb.rumbleon.com";
  INVENTORY_API_ENDPOINT = "https://v4pprodinventory.rumbleon.com";
  REACT_APP_CW_CASH_OFFER = "https://v4pprodcashoffer.rumbleon.com";
  REACT_APP_DEALER_DETAIL = "https://v4pprodorg.rumbleon.com"; //@todo: update
  REACT_APP_STRIPE_KEY = "pk_test_bZ8UlmeKkUzngIMF0WDLXx5L";
  REACT_APP_SEGMENT_KEY = "WxPVwF17NiKb5j9knGDWED6TnKpNGOSZ";
  SCHEMA_PATH = "https://v4graphql.rumbleon.com/pprod";
  GOOGLE_RECAPTCHA_KEY = "6LfKAKYZAAAAAMROukZEPWDklu1bSjxVimITbVAP";
  ACCEPT_OFFER_SF_URL =
    "https://rumbleon--preprod.sandbox.my.salesforce-sites.com";
  DEALER_PORTAL_URL = "https://v4pproddealer.rumbleon.com";
  AUTH_API_ENDPOINT = "https://v4pprodaccounts.rumbleon.com";
  VINLESS_ES_URL =
    "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243";
  REACT_MARINEVINLESS_ES_APP = "v4pprodnadaymmmarine";
  REACT_MARINEVINLESS_ES_LINK =
    "https://b9bff4f64d8c49488791b9c344a8d05f.us-east-1.aws.found.io:9243";
  CAREERS_JOB = "https://v4pprodcareersapi.rumbleon.com/api/Careers/GetJobs";
  CAREERS_KEY = "Basic ZGV2LXFhLXJ3OkNrOXE5dTNKekc=";
}
//DEMO
if (window.location.hostname === "dev-v5consumerweb.rumbleon.com") {
   ENGINE_API_KEY = "service:JoeDevGeeeee-2839:3vtgQng6FUHL6WIfT0szDQ";
   REACT_APP_GRAPHQL_ENDPOINT = "https://v4graphql.rumbleon.com/dev";
   REACT_APP_S3_LINK = "https://devsellimg.rumbleon.com";
   REACT_APP_SECURED_S3_LINK = "https://dev-doc-sf-ron-s3.s3.amazonaws.com";
   REACT_APP_SECURED_S3_KEY = "AKIAUZM55CJFVVPXAGHK";
   REACT_APP_MAPS_CREDENTIALS = "AIzaSyAMbbBkddD11O3RX39-9mDXBywrNdzWYog";
   REACT_S3_ROTATE = "https://s3.amazonaws.com/bikeimages-ron-dev-s3";
   REACT_DEALER_S3_LINK = "https://devdlrimg.rumbleon.com";
   REACT_APP_CLS_API_ENDPOINT = "https://v4devclsapi.rumbleon.com";
   REACT_APP_ES_APP = "v4devlisting";
   REACT_DEALER_ES_APP = "v4devdealership";
   TCI_URL =
    "https://cp-demo.decisionlender.solutions/cp/#/rumb040/bike/newapp?";
   REACT_APP_GTM_ID = "GTM-NLTX2F8";
   REACT_APP_ES_CREDENTIALS = "dev-qa-ro:dX4FZJwcVF";
   REACT_APP_ES_LINK = "https://v4devesproxy.rumbleon.com";
   REACT_DEALER_ES_LINK =
    "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243";
   REACT_APP_RMBL_BASE = "https://v4devconsumerweb.rumbleon.com";
   INVENTORY_API_ENDPOINT = "https://v4devinventory.rumbleon.com";
   REACT_ES_BASIC_AUTH = "Basic ZGV2LXFhLXJvOmRYNEZaSndjVkY=";
   REACT_APP_CW_CASH_OFFER = "https://v4devcashoffer.rumbleon.com";
   REACT_APP_DEALER_DETAIL = "https://v4devorg.rumbleon.com"; //@todo: update
   REACT_APP_STRIPE_KEY = "pk_test_bZ8UlmeKkUzngIMF0WDLXx5L";
   REACT_APP_SEGMENT_KEY = "WxPVwF17NiKb5j9knGDWED6TnKpNGOSZ";
   SCHEMA_PATH = "https://v4graphql.rumbleon.com/dev";
   GOOGLE_RECAPTCHA_KEY = "6LfKAKYZAAAAAMROukZEPWDklu1bSjxVimITbVAP";
   ACCEPT_OFFER_SF_URL = "http://dev-rumbleon.cs41.force.com";
   DEALER_PORTAL_URL = "https://v4devdealer.rumbleon.com/";
   AUTH_API_ENDPOINT = "https://v4devaccounts.rumbleon.com";
   VINLESS_ES_URL =
    "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243";
   REACT_MARINEVINLESS_ES_APP = "v4pprodnadaymmmarine";
   REACT_MARINEVINLESS_ES_LINK =
    "https://b9bff4f64d8c49488791b9c344a8d05f.us-east-1.aws.found.io:9243";
   CAREERS_JOB = "https://v4pprodcareersapi.rumbleon.com/api/Careers/GetJobs";
   CAREERS_KEY = "Basic ZGV2LXFhLXJ3OkNrOXE5dTNKekc=";
  // REACT_MARINEVINLESS_ES_APP = "devnadaymmmarine";
  // REACT_MARINEVINLESS_ES_LINK =
  //   "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243";
}
// PROD
if (
  window.location.hostname === "www.rumbleon.com" ||
  window.location.hostname === "virginiaconsumerweb.rumbleon.com" ||
  window.location.hostname === "ohioconsumerweb.rumbleon.com"
) {
  ENGINE_API_KEY = "service:CLS-PROD:wz0Hjcagrfb_aEQRm6bqPQ";
  REACT_APP_GRAPHQL_ENDPOINT = "https://v4graphql.rumbleon.com/production";
  REACT_APP_S3_LINK = "https://sellimg.rumbleon.com";
  REACT_APP_SECURED_S3_LINK = "https://prod-doc-sf-ron-s3.s3.amazonaws.com";
  REACT_APP_MAPS_CREDENTIALS = "AIzaSyAMbbBkddD11O3RX39-9mDXBywrNdzWYog";
  REACT_APP_SECURED_S3_KEY = "AKIAUZM55CJFVVPXAGHK";
  REACT_S3_ROTATE = "https://s3.amazonaws.com/rumbleon-sell-images";
  REACT_DEALER_S3_LINK = "https://dlrimg.rumbleon.com";
  REACT_APP_CLS_API_ENDPOINT = "https://clsapi.rumbleon.com";
  REACT_APP_ES_APP = "v4prodlisting";
  REACT_DEALER_ES_APP = "v4proddealership";
  TCI_URL = "https://cp.decisionlender.solutions/cp/#/rumb040/bike/newapp?";
  REACT_APP_GTM_ID = "GTM-NLTX2F8";
  REACT_APP_ES_CREDENTIALS = "cweb-prod-ro:nw$bR!2cs879";
  REACT_ES_BASIC_AUTH = "Basic Y3dlYi1wcm9kLXJvOm53JGJSITJjczg3OQ==";
  REACT_APP_ES_LINK = "https://prodesproxy.rumbleon.com";
  REACT_DEALER_ES_LINK =
    "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243";
  REACT_APP_RMBL_BASE = "https://www.rumbleon.com";
  INVENTORY_API_ENDPOINT = "https://inventory.rumbleon.com";
  REACT_APP_CW_CASH_OFFER = "https://cashoffer.rumbleon.com";
  REACT_APP_DEALER_DETAIL = "https://org.rumbleon.com";
  REACT_APP_STRIPE_KEY = "sk_live_pIYw9bnADfs141pksMRpT2Ez";
  REACT_APP_SEGMENT_KEY = "KbQHjkSQfITWUPPH853oOUAIw6xXpu9W";
  SCHEMA_PATH = "https://v4graphql.rumbleon.com/production";
  GOOGLE_RECAPTCHA_KEY = "6LfKAKYZAAAAAMROukZEPWDklu1bSjxVimITbVAP";
  ACCEPT_OFFER_SF_URL = "https://rumbleon.secure.force.com/";
  DEALER_PORTAL_URL = "https://dealer.rumbleon.com";
  AUTH_API_ENDPOINT = "https://accounts.rumbleon.com";
  VINLESS_ES_URL =
    "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243";
  REACT_MARINEVINLESS_ES_APP = "v4prodnadaymmmarine";
  REACT_MARINEVINLESS_ES_LINK =
    "https://b9bff4f64d8c49488791b9c344a8d05f.us-east-1.aws.found.io:9243";
  CAREERS_JOB = "https://careersapi.rumbleon.com/api/Careers/GetJobs";
  CAREERS_KEY = "Basic ZGV2LXFhLXJ3OkNrOXE5dTNKekc=";
}
if (window.location.hostname === "prod.rumbleon.com") {
  ENGINE_API_KEY = "service:CLS-PROD:wz0Hjcagrfb_aEQRm6bqPQ";
  REACT_APP_GRAPHQL_ENDPOINT = "https://v4graphql.rumbleon.com/production";
  REACT_APP_S3_LINK = "https://sellimg.rumbleon.com";
  REACT_APP_SECURED_S3_LINK = "https://prod-doc-sf-ron-s3.s3.amazonaws.com";
  REACT_APP_SECURED_S3_KEY = "AKIAUZM55CJFVVPXAGHK";
  REACT_S3_ROTATE = "https://s3.amazonaws.com/rumbleon-sell-images";
  REACT_DEALER_S3_LINK = "https://dlrimg.rumbleon.com";
  REACT_APP_CLS_API_ENDPOINT = "https://clsapi.rumbleon.com";
  REACT_APP_ES_APP = "v4prodlisting";
  REACT_APP_MAPS_CREDENTIALS = "AIzaSyAMbbBkddD11O3RX39-9mDXBywrNdzWYog";
  REACT_DEALER_ES_APP = "v4proddealership";
  TCI_URL = "https://cp.decisionlender.solutions/cp/#/rumb040/bike/newapp?";
  REACT_APP_GTM_ID = "GTM-NLTX2F8";
  REACT_APP_ES_CREDENTIALS = "cweb-prod-ro:nw$bR!2cs879";
  REACT_ES_BASIC_AUTH = "Basic Y3dlYi1wcm9kLXJvOm53JGJSITJjczg3OQ==";
  REACT_APP_ES_LINK = "https://prodesproxy.rumbleon.com";
  REACT_DEALER_ES_LINK =
    "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243";
  REACT_APP_RMBL_BASE = "https://www.rumbleon.com";
  INVENTORY_API_ENDPOINT = "https://inventory.rumbleon.com";
  REACT_APP_CW_CASH_OFFER = "https://cashoffer.rumbleon.com";
  REACT_APP_DEALER_DETAIL = "https://org.rumbleon.com";
  REACT_APP_STRIPE_KEY = "sk_live_pIYw9bnADfs141pksMRpT2Ez";
  REACT_APP_SEGMENT_KEY = "KbQHjkSQfITWUPPH853oOUAIw6xXpu9W";
  SCHEMA_PATH = "https://v4graphql.rumbleon.com/production";
  GOOGLE_RECAPTCHA_KEY = "6LfKAKYZAAAAAMROukZEPWDklu1bSjxVimITbVAP";
  ACCEPT_OFFER_SF_URL = "https://rumbleon.secure.force.com/";
  DEALER_PORTAL_URL = "https://dealer.rumbleon.com";
  VINLESS_ES_URL =
    "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243";
  REACT_MARINEVINLESS_ES_APP = "v4prodnadaymmmarine";
  REACT_MARINEVINLESS_ES_LINK =
    "https://b9bff4f64d8c49488791b9c344a8d05f.us-east-1.aws.found.io:9243";
  AUTH_API_ENDPOINT = "https://accounts.rumbleon.com";
  CAREERS_JOB = "https://careersapi.rumbleon.com/api/Careers/GetJobs";
  CAREERS_KEY = "Basic ZGV2LXFhLXJ3OkNrOXE5dTNKekc=";
}

export const environmentVariableList = {
  ENGINE_API_KEY,
  REACT_APP_GRAPHQL_ENDPOINT,
  REACT_APP_S3_LINK,
  REACT_APP_SECURED_S3_LINK,
  REACT_APP_SECURED_S3_KEY,
  REACT_DEALER_S3_LINK,
  REACT_APP_MAPS_CREDENTIALS,
  REACT_APP_CLS_API_ENDPOINT,
  REACT_APP_ES_APP,
  REACT_DEALER_ES_APP,
  TCI_URL,
  REACT_APP_GTM_ID,
  REACT_APP_ES_CREDENTIALS,
  REACT_APP_ES_LINK,
  REACT_ES_BASIC_AUTH,
  REACT_DEALER_ES_LINK,
  REACT_APP_RMBL_BASE,
  REACT_APP_CW_CASH_OFFER,
  INVENTORY_API_ENDPOINT,
  REACT_APP_DEALER_DETAIL,
  REACT_APP_STRIPE_KEY,
  REACT_APP_SEGMENT_KEY,
  SCHEMA_PATH,
  GOOGLE_RECAPTCHA_KEY,
  ACCEPT_OFFER_SF_URL,
  DEALER_PORTAL_URL,
  REACT_S3_ROTATE,
  AUTH_API_ENDPOINT,
  VINLESS_ES_URL,
  REACT_MARINEVINLESS_ES_APP,
  REACT_MARINEVINLESS_ES_LINK,
  CAREERS_JOB,
  CAREERS_KEY,
};
