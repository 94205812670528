import {
  AcceptOfferSuccessModal,
  FinanceNonTCIStateModal,
  RequestInfoModal,
} from "./Modals";
import { BackTop, Spin } from "antd";
import { Global, css } from "@emotion/core";
import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import AcceptFinanceOptInLanguage from "./Modals/AcceptFinanceOptInLanguage";
import AcceptTOU from "./Modals/AcceptTOU";
import ApolloWrapper from "./components/ApolloWrapper/ApolloWrapper";
// import { FilterFlowContextProvider } from "./context/FilterContext";
import { AuthPopupContextProvider } from "./context/AuthPopupContext";
import { CashOfferContextProvider } from "./context/CashOfferContext";
import CreatePassword from "./components/CreatePassword/SetPasswordForm";
import { DetailsFlowContextProvider } from "./context/DetailsFlowContext";
import { GeolocationContextProvider } from "./context/GeolocationContext";
import { GlobalFlowContextProvider } from "./context/Global";
import Layout from "./components/Layout/Layout";
import MaintainanceAsync from "./components/Maintainance/maintainance";
import { ModalFlowContextProvider } from "./context/ModalContext";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { SellFlowContextProvider } from "./context/SellFlowContext";

const AsyncHomePage = lazy(() => import("./components/Home/Home"));

const AsyncCashOfferPage = lazy(() =>
  import("./components/CashOfferPage/CashOfferPage")
);

const AsyncDealerOfferPage = lazy(() =>
  import("./components/CashOfferPage/DealerOfferPage")
);

const AsyncBuyPage = lazy(() => import("./components/BuyPage/BuyPage"));

const AsyncSellerViewPage = lazy(() =>
  import("./components/VehicleDetailSellerView/VehicleDetailSellerView")
);
// const AsyncListVehiclePage = lazy(() =>
//   import("./components/ListVehiclePage/ListVehiclePage")
// );
const AsyncSellerInfoPage = lazy(() =>
  import("./components/SellerInformationForm/SellerInformationPage")
);

const AsyncSellInfo = lazy(() => import("./components/how-to"));

const AsyncResetPasswordPage = lazy(() =>
  import("./components/ResetPasswordPage/ResetPasswordPage")
);
const AsyncLogInPopUp = lazy(() =>
  import("./components/LoginPopUp/LoginPopUp")
);
const AsyncSignupPopup = lazy(() =>
  import("./components/SignupPopup/SignupPopup")
);

const AsyncMyGarage = lazy(() => import("./components/MyGarage/MyGarage"));

const AsyncLogin = lazy(() => import("./components/Login/Login"));

const AsyncAcceptOffer = lazy(() => import("./components/CashOffer/Cashoffer"));
const AsyncAcceptOfferRideNow = lazy(() =>
  import("./components/CashOffer/RideNowCashoffer")
);
const AsyncThankYouInStoreAcceptance = lazy(() =>
  import("./components/CashOffer/ThankYouInstoreAcceptance")
);
const AsyncDealerSearch = lazy(() =>
  import("./components/DealerSearch/DealerSearch")
);
const AsyncThankYouVinlessAcceptanceAsync = lazy(() =>
  import("./components/CashOffer/ThankYouVinlessAcceptance")
);
const AsyncRenewOffer = lazy(() =>
  import("./components/RenewOffer/RenewOffer")
);

const AsyncLiveAuction = lazy(() =>
  import("./components/LiveAuction/LiveAuction")
);

const AsyncFinance = lazy(() => import("./components/Finance/Finance"));

const AsyncTCIFinancing = lazy(() =>
  import("./components/Finance/TCI/TCI-financing")
);

const AsyncFinancing = lazy(() =>
  import("./components/AdditionalServices/Finance")
);

const AsyncDetailContainer = lazy(() =>
  import("./components/VehicleDetail/VehicleDetail")
);

const AsyncLegacyAuthRedirect = lazy(() =>
  import("./components/LegacyAuthRedirect/LegacyAuthRedirect")
);
const AsyncTradeInLeadPage = lazy(() =>
  import("./components/TradeInleadPage/TradeInLeadPage")
);
const AsyncPrivacy = lazy(() => import("./components/Home/Privacy"));

const AsyncFaq = lazy(() => import("./components/Home/Faq"));

const AsyncTermsofUse = lazy(() => import("./components/Home/TermsofUse"));

const AsyncAboutUs = lazy(() => import("./components/AboutUs/AboutUs"));

const AsyncContactUs = lazy(() => import("./components/ContactUs/ContactUs"));

const AsyncCareers = lazy(() => import("./components/Careers/Careers"));

// const AsyncAdditionalServices = lazy(() =>
//   import("./components/AdditionalServices/AdditionalServicesHome")
// );
// const AsyncCreatePassword = lazy(() =>
//   import("./components/CreatePassword/CreatePassword")
// );
// const AsyncInsurance = lazy(() =>
//   import("./components/AdditionalServices/Insurance")
// );
// const AsyncInspection = lazy(() =>
//   import("./components/AdditionalServices/Inspection")
// );
// const AsyncTransportation = lazy(() =>
//   import("./components/AdditionalServices/Transportation")
// );
// const AsyncSafeXChange = lazy(() =>
//   import("./components/AdditionalServices/SafeXChange")
// );
// const AsyncRumbleonInventory = lazy(() =>
//   import("./components/RumbleonInventory/rumbleonInventoary")
// );
// const AsyncRumbleOnFinance = lazy(() =>
//   import("./components/Home/RumbleOnFinance")
// );

const globalStyle = css`
  body,
  li,
  p {
    font-family: "aktiv-grotesk", Arial, Helvetica, sans-serif !important;
  }
`;

const App: React.FC = () => {
  return (
    <AuthPopupContextProvider>
      <GlobalFlowContextProvider>
        <ModalFlowContextProvider>
          {/* <FilterFlowContextProvider> */}
          <SellFlowContextProvider>
            <DetailsFlowContextProvider>
              <CashOfferContextProvider>
                <GeolocationContextProvider>
                  <ApolloWrapper>
                    <div>
                      <Layout>
                        <Global styles={globalStyle} />
                        <AcceptOfferSuccessModal />
                        <AcceptTOU />
                        <AcceptFinanceOptInLanguage />
                        <FinanceNonTCIStateModal />
                        <RequestInfoModal />

                        <Suspense
                          fallback={
                            <div
                              css={css`
                                text-align: center;
                                padding-top: 35vh;
                                min-height: 75vh;
                                min-width: 80vw;
                              `}
                            >
                              <Spin spinning />
                            </div>
                          }
                        >
                          <ScrollToTop />
                          <Switch>
                            <Route path="/" exact component={AsyncHomePage} />
                            <Route
                              exact
                              path="/buy/:query?/"
                              component={AsyncBuyPage}
                            />
                            <Route
                              exact
                              path="/buy/:listingName?/:listingId?/:typeId?"
                              render={() => (
                                <AsyncDetailContainer key={Math.random()} />
                              )}
                              // changed to render from component due to url not rerendering due to link not being unique
                              // solved by adding a key.
                            />
                            <Route
                              exact
                              path="/detail/:listingId"
                              component={AsyncDetailContainer}
                            />
                            <Route
                              exact
                              path="/detail/seller/edit/:listingId"
                              component={AsyncSellerViewPage}
                            />
                            {/* <Route
                              path="/list-your-vehicle"
                              component={AsyncListVehiclePage}
                            /> */}
                            <Route
                              path="/sell/:param?"
                              component={AsyncCashOfferPage}
                            />
                            <Route
                              path="/list-your-vehicle-for-a-dealer/:param?"
                              component={AsyncDealerOfferPage}
                            />
                            <Route
                              path="/my-garage"
                              component={AsyncMyGarage}
                            />
                            <Route
                              path="/listing-services/optional-services"
                              render={() => (
                                <Redirect to="/listing-services/additional-services" />
                              )}
                            />
                            <Route
                              exact
                              path="/login-tci"
                              component={AsyncLogin}
                            />
                            <Route
                              path="/others/accept-offer"
                              component={AsyncAcceptOffer}
                            />
                            <Route
                              path="/others/accept-offer-dealer"
                              component={AsyncAcceptOfferRideNow}
                            />
                            <Route path="/careers" component={AsyncCareers} />
                            <Route
                              path="/others/instore-thankyou"
                              component={AsyncThankYouInStoreAcceptance}
                            />
                            <Route
                              path="/others/vinless-thankyou"
                              component={AsyncThankYouVinlessAcceptanceAsync}
                            />
                            <Route
                              path="/sell-vehicle/:params?"
                              component={AsyncRenewOffer}
                            />
                            <Route
                              path="/live-auction"
                              component={AsyncLiveAuction}
                            />
                            {/* <Route
                          path="/listing-services/additional-services"
                          component={AsyncAdditionalServices}
                        /> */}
                            {/* <Route
                          path="/additional-services"
                          component={AsyncAdditionalServices}
                        /> */}
                            {/* <Route
                          path="/optional-services"
                          component={AsyncAdditionalServices}
                        /> */}
                            {/* <Route
                          path="/listing-services/add-service-protection"
                          component={AsyncAdditionalServices}
                        /> */}
                            <Route
                              path="/how-to/:param?"
                              component={AsyncSellInfo}
                            />
                            <Route
                              path="/listing-services/get-financing-approval"
                              component={AsyncFinancing}
                            />
                            {/* <Route path="/financing" component={AsyncFinancing} /> */}
                            {/* <Route path="/finance" component={AsyncFinance} /> */}
                            <Route
                              path="/get-financing"
                              component={AsyncTCIFinancing}
                            />
                            <Route
                              path="/dealerships"
                              component={AsyncDealerSearch}
                            />
                            {/* <Route
                          path="/listing-services/get-insurance-quote"
                          component={AsyncInsurance}
                        /> */}
                            {/* <Route path="/insurance" component={AsyncInsurance} /> */}
                            {/* <Route
                          path="/listing-services/order-inspection-report/:listingId?/:listingName?"
                          component={AsyncInspection}
                        /> */}
                            {/* <Route
                          path="/inspection/:listingId?/:listingName?"
                          component={AsyncInspection}
                        /> */}
                            {/* <Route
                          path="/listing-services/safe-exchange/:listingId?/:listingName?"
                          component={AsyncSafeXChange}
                        /> */}
                            {/* <Route
                          path="/safexchange/:listingId?/:listingName?"
                          component={AsyncSafeXChange}
                        /> */}
                            {/* <Route
                          path="/listing-services/shipping-and-transportation-options/:listingId?/:listingName?"
                          component={AsyncTransportation}
                        /> */}
                            {/* <Route
                          path="/transportation/:listingId?/:listingName?"
                          component={AsyncTransportation}
                        /> */}
                            <Route
                              path="/auth/reset-password"
                              component={AsyncResetPasswordPage}
                            />
                            <Route
                              path="/auth/forgot-password"
                              component={AsyncLegacyAuthRedirect}
                            />
                            <Route
                              path="/auth/create-password"
                              component={CreatePassword}
                            />
                            <Route
                              path="/auth/sign-in-to-your-account"
                              component={AsyncLegacyAuthRedirect}
                            />
                            <Route
                              path="/auth/sign-up-for-an-account"
                              component={AsyncLegacyAuthRedirect}
                            />
                            {/* <Route
                          path="/rumbleon-inventory"
                          component={AsyncRumbleonInventory}
                        /> */}

                            <Route
                              path="/others/privacy-policy"
                              component={AsyncPrivacy}
                            />
                            <Route path="/privacy" component={AsyncPrivacy} />
                            {/* <Route path="/terms-of-use" component={AsyncTermsofUse} /> */}
                            <Route
                              path="/termsofuse"
                              component={AsyncTermsofUse}
                            />
                            <Route
                              path="/thank-you-trade-offer"
                              component={AsyncTradeInLeadPage}
                            />
                            <Route path="/faq" component={AsyncFaq} />
                            {/* <Route
                              path="/rumbleon-finance"
                              component={AsyncRumbleOnFinance}
                            /> */}
                            <Route path="/about-us" component={AsyncAboutUs} />
                            <Route
                              path="/contact-us"
                              component={AsyncContactUs}
                            />
                            <Route component={AsyncHomePage} />
                            <Route component={MaintainanceAsync} />
                          </Switch>
                          <AsyncLogInPopUp />
                          <AsyncSignupPopup />
                          <BackTop visibilityHeight={500} />
                        </Suspense>
                      </Layout>
                    </div>
                  </ApolloWrapper>
                </GeolocationContextProvider>
              </CashOfferContextProvider>
            </DetailsFlowContextProvider>
          </SellFlowContextProvider>
          {/* </FilterFlowContextProvider> */}
        </ModalFlowContextProvider>
      </GlobalFlowContextProvider>
    </AuthPopupContextProvider>
  );
};

export default App;
