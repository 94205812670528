import * as React from "react";

import { Button, Col, Menu, Modal, Row } from "antd";
import { Link, useHistory } from "react-router-dom";
import {
  useAuthPopupDispatch,
  useAuthPopupState,
} from "../../../context/AuthPopupContext";

import Logo from "../../Layout/images/rumbleon-black-logo.svg";
import { colors } from "../../../constants/styles";
import { css } from "@emotion/core";
import { environmentVariableList } from "../../../config/env";
import { useCashOfferContext } from "../../../context/CashOfferContext";

const MobileTopLeftMenu: React.FC = () => {
  const history = useHistory();
  const dispatch = useAuthPopupDispatch();
  const { isLoggedIn } = useAuthPopupState();
  const [modalVisible, setModalVisble] = React.useState<boolean>(false);
  const { state } = useCashOfferContext();
  const ItemTypeId = state?.decodeData?.ItemTypeId;

  const handleClose = () => {
    setModalVisble(false);
  };

  const handleLogout = () => {
    setModalVisble(false);
    dispatch({
      type: "LOG_OUT",
    });
    history.push("/");
  };
  const handleLogin = () => {
    setModalVisble(false);
    dispatch({
      type: "SHOW_LOGIN",
    });
  };

  const handleCreateAccount = () => {
    setModalVisble(false);
    dispatch({
      type: "SHOW_SIGNUP",
    });
  };

  const toggleCollapsed = () => {
    if (modalVisible === false) {
      setModalVisble(true);
    } else {
      setModalVisble(false);
    }
  };

  const { SubMenu } = Menu;
  return (
    <>
      <Button
        css={css`
          width: 70px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent !important;
          color: #2b2b2b;
          font-size: 20px !important;
          padding: 0 !important;
          box-shadow: 0 0 0 0 !important;
          outline: 0;
          border: none !important;
          &:after {
            animation-duration: 0s !important;
          }
        `}
        ghost={true}
        icon="menu"
        type="primary"
        onClick={toggleCollapsed}
      />
      <Modal
        visible={modalVisible}
        onCancel={handleClose}
        closable={true}
        footer={null}
        destroyOnClose
        title={
          <img
            css={css`
              width: 200px;
            `}
            alt={"Rumbleon Logo"}
            src={Logo}
          />
        }
        css={css`
          .ant-menu {
            border: none;
          }
          .ant-menu-item,
          .ant-menu-submenu-title {
            padding: 0 !important;
            font-family: interstate;
            font-weight: bold;
            color: inherit;
          }

          .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
            background-color: transparent;
            &:after {
              border-right: none;
            }
          }
        `}
        style={{
          borderRadius: "3px",
          top: "5%",
          width: "100%",
        }}
      >
        <Row>
          <Col span={24}>
            <Menu mode="inline" selectedKeys={[]}>
              {isLoggedIn && (
                <Menu.Item key="1">
                  <Link to="/my-garage" onClick={handleClose}>
                    My Garage
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item key="2">
                <Link to="/" onClick={handleClose}>
                  Home
                </Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to="/buy" onClick={handleClose}>
                  Buy
                </Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link
                  to={{
                    pathname:
                      (state.dealerBrandType === "campaign" &&
                        ItemTypeId === 1) ||
                      window.location.pathname === "/how-to/sell-your-vehicle-1"
                        ? "/how-to/sell-your-vehicle-1"
                        : (state.dealerBrandType === "campaign" &&
                            ItemTypeId === 3) ||
                          window.location.pathname ===
                            "/how-to/sell-your-boat-1"
                        ? "/how-to/sell-your-boat-1"
                        : "/how-to/sell-your-vehicle",
                    state: {
                      refresh: true,
                    },
                  }}
                  onClick={handleClose}
                >
                  Cash offer
                </Link>
              </Menu.Item>
              {!isLoggedIn && (
                <Menu.Item key="6">
                  <Link
                    to="/list-your-vehicle-for-a-dealer"
                    onClick={handleClose}
                  >
                    Dealer
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item key="5">
                <Link to="/dealerships" onClick={handleClose}>
                  Find a Store
                </Link>
              </Menu.Item>
              {/* <Menu.Item key="5">
                <Link to="/list-your-vehicle" onClick={handleClose}>
                  List
                </Link>
              </Menu.Item> */}
              {/* <Menu.Item key="6">
                <Link to="/finance" onClick={handleClose}>
                  Get Pre-qualified
                </Link>
              </Menu.Item> */}
              {/* <Menu.Item key="7">
                <Link to="/dealerships" onClick={handleClose}>
                  Find a Dealer
                </Link>
              </Menu.Item>
              <Menu.Item key="8">
                <a onClick={() => {
                  window.open(
                    "https://www.ridenowauctions.com",
                    "_blank"
                  )
                }} >Live Auction</a>
              </Menu.Item> */}
              {/* @todo find the right link */}
              {/* <Menu.Item key="8">
                <a
                  href={environmentVariableList.DEALER_PORTAL_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Dealer Portal
                </a>
              </Menu.Item> */}
              <SubMenu key="sub1" title={"About RumbleOn"}>
                <Menu.Item key="11">
                  <Link to="/about-us" onClick={handleClose}>
                    About Us
                  </Link>
                </Menu.Item>

                <Menu.Item key="13">
                  <Link to="/contact-us" onClick={handleClose}>
                    Contact Us
                  </Link>
                </Menu.Item>
                <Menu.Item key="faq" onClick={handleClose}>
                  <Link to="/faq">FAQ</Link>
                </Menu.Item>

                <Menu.Item key="14">
                  <a
                    href="https://go.rumbleon.com/careers-at-rumbleon"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleClose}
                  >
                    Careers
                  </a>
                </Menu.Item>
                <Menu.Item key="15">
                  <a
                    href="https://investors.rumbleon.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleClose}
                  >
                    Investors
                  </a>
                </Menu.Item>
                <Menu.Item key="16">
                  <a
                    href="https://explore.rumbleon.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleClose}
                  >
                    Blog
                  </a>
                </Menu.Item>
                <Menu.Item key="17">
                  <a
                    href="https://go.rumbleon.com/rally-with-rumbleon-upcoming-events"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleClose}
                  >
                    Events
                  </a>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Col>
          <Col
            span={24}
            css={css`
              font-weight: bold;
              font-family: interstate;
              margin-top: 1rem;
            `}
          >
            {isLoggedIn ? (
              <Button
                size="large"
                icon={"user"}
                block
                css={css`
                  background: ${colors.darkBlack};
                  color: white;
                `}
                onClick={handleLogout}
              >
                LOG OUT
              </Button>
            ) : (
              <Row gutter={[8, 8]}>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Button block type="ghost" size="large" onClick={handleLogin}>
                    LOG IN
                  </Button>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Button
                    block
                    type="default"
                    size="large"
                    css={css`
                      background: ${colors.darkBlack};
                      color: white;
                    `}
                    onClick={handleCreateAccount}
                  >
                    CREATE ACCOUNT
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default MobileTopLeftMenu;
